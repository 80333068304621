<template>
  <div>
    <van-form ref="form">
      <!-- 基本信息 -->
      <van-cell-group title="离校基本信息">
        <custom-select ref="housingNo" required v-model="form.teremYear" placeholder="请选择学年" label="学年" :columns="dict.xj_nj" />
        <custom-select ref="schoolTerem" required v-model="form.schoolTerem" placeholder="请选择学期" label="学期" labelKey="label" valueKey="label" :columns="dict.xj_xqgl" />
        <custom-select ref="classNo" required labelKey="className" valueKey="classNo" v-model="form.classNo" placeholder="请选择班级" label="班级" @change="changeClass" :columns="classDatas" />
        <van-field v-model="form.staffName" placeholder="填写辅导员" readonly label="辅导员" />
        <custom-select ref="leaveSchoolType" required v-model="form.leaveSchoolType" placeholder="请选择离校类型" label="离校类型" @change="changeSchoolTypeHandle" :columns="dict.leave_school_type" />
        <van-field v-model="form.otherExplanation" forms="3" placeholder="班级情况说明（毕业应离校人数、实际离校人数及不予办理离校学生信息等）" class="textarea-style" label="离校说明" type="textarea" />
      </van-cell-group>
      <van-cell-group title="离校学生名单" style="margin-bottom:64px" :border="true">
        <van-cell v-for="(stu,index) in form.leaveSchoolStudents" :key="index" :border="true">
          <template #title>
            <div style="display: flex;">
              <van-checkbox v-model="stu.checked" icon-size="21px" shape="square"></van-checkbox>
              <span style="margin-left:10px">{{(index+1)+'-'+stu.stuName}}</span>
            </div>
          </template>
          <template #right-icon>
            <span style="margin-left:10px">{{ stu.stuNo}}</span>
          </template>
        </van-cell>
      </van-cell-group>
    </van-form>
    <div class="submit-save shadow-style">
      <van-button round block type="primary" class="submit-save-subbtn" :loading="submitLoading" @click="doSubmit">提 交</van-button>
    </div>
  </div>
</template>

<script>
import { getClassNoPage } from '@/api/modules/xgdw/classInfo'
import { getStuinfoNotPage } from '@/api/modules/xj/stuInfo'
import { mapGetters } from 'vuex'
import crudLeaveSchool from '@/api/modules/xj/leave'
import { getTermYear, getTerm } from '@/utils/index'
import { Dialog } from "vant";
import { validCustom } from "@/utils";


export default {
  name: "LeaveForm",
  dicts: ['xj_xqgl', 'xj_nj', 'leave_school_type'],
  data() {
    return {
      submitLoading: false,
      form: {
        id: null,
        teremYear: null,
        leaveSchoolType: null,
        schoolTerem: null,
        otherExplanation: null,
        classNo: null,
        staffNo: null,
        facultyName: null,
        facultyNo: null,
        specCode: null,
        specName: null,
        className: null,
        staffName: null,
        leaveSchoolStudents: [],
      },
      classDatas: [],
      customRuleFileds: ["housingNo", "schoolTerem", "classNo", "leaveSchoolType"],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      roles: 'roles',
      token: 'token'
    }),
  },
  created() {
    this.form.staffNo = this.user.username
    if (this.$route.query.id) {
      this.editInit()
    } else {
      this.initData()
    }
  },
  methods: {
    doSubmit() {
      // 提交
      this.$refs.form.validate().then(
        (res) => {
          if (validCustom(this.customRuleFileds, this)) {
            // 数据过滤
            this.form.leaveSchoolStudents = this.form.leaveSchoolStudents.filter((stuInfo) => stuInfo.checked)
            if (this.form.leaveSchoolStudents.length < 1) {
              this.$notify({
                type: "warning",
                message: "请筛选离校学生！",
                duration: 1500,
              });
              return false
            }
            // 验证通过数据提交
            Dialog.confirm({
              title: "提示",
              message: "你确定要进行提交操作吗？",
            })
              .then(() => {
                this.submitLoading = true
                if (this.$route.query.id) {
                  this.submitEdit()
                } else {
                  this.submitAdd()
                }
              })
              .catch(() => {
                // on cancel
              });
          } else {
            this.$notify({
              type: "warning",
              message: "有提交项未填写！",
              duration: 1500,
            });
          }
        },
        (err) => {
          validCustom(this.customRuleFileds, this);
          this.$notify({
            type: "warning",
            message: "有提交项未填写！",
            duration: 1500,
          });
        }
      );
    },
    submitAdd() {
      crudLeaveSchool
        .add(this.form)
        .then((res) => {
          this.$notify({
            message: '添加成功',
            type: 'success',
            duration: 2000,
          })
          this.submitLoading = false
          this.cancel()
        })
        .catch((err) => {
          this.submitLoading = false
        })
    },
    submitEdit() {
      crudLeaveSchool
        .edit(this.form)
        .then((res) => {
          this.$notify({
            message: '编辑成功',
            type: 'success',
            duration: 2000,
          })
          this.submitLoading = false
          this.cancel()
        })
        .catch((err) => {
          this.submitLoading = false
        })
    },
    async initData() {
      this.classDatas = []
      await getClassNoPage({ staffNo: this.user.username }).then((res) => {
        if (res.length > 0) {
          this.classDatas = res
          this.form.classNo = res[0].classConslr.classNo
          this.form.staffName = res[0].classConslr.conslrStaffName
          this.form.staffNo = res[0].classConslr.conslrStaffNo
          this.changeClassHandle(this.form.classNo)
        }
        // 默认学期学年
        let termYear = getTermYear(
          this.dict.xj_xqgl,
          (new Date().getMonth() + 1).toString()
        )
        let term = getTerm(
          this.dict.xj_xqgl,
          (new Date().getMonth() + 1).toString()
        )
        this.form.schoolTerem = term.xqName
        this.form.teremYear = termYear
      })
    },
    // 初始化编辑
    editInit() {
      this.classDatas = []
      getClassNoPage({ staffNo: this.user.username }).then((res) => {
        this.classDatas = res
      })
      crudLeaveSchool
        .findLeaveStuDataById(this.$route.query.id)
        .then((data) => {
          this.form = data
          this.form.leaveSchoolStudents = this.form.leaveSchoolStudents.map((e) => {
            let a = { ...e }
            a.checked = true
            return a;
          })
        })
    },
    // 离校类型变化
    changeSchoolTypeHandle(code) {
      let param = []
      if (code === '1') {
        param = ['0']
      } else {
        param = ['0', '1']
      }
      this.changeClassHandle(this.form.classNo, param)
    },
    // 班级变化
    changeClass(code) {
      this.changeClassHandle(code);
    },
    changeClassHandle(code, id) {
      this.form.leaveSchoolStudents = []
      getStuinfoNotPage({
        classNo: code,
        leaveSchoolStudents: id ? id : [0],
      }).then((stulist) => {
        if (stulist && stulist.length) {
          this.form.facultyName = stulist[0].collName
          this.form.facultyNo = stulist[0].collNo
          this.form.specCode = stulist[0].sqecCode
          this.form.specName = stulist[0].sqecName
          this.form.className = stulist[0].className
          stulist.forEach((element) => {
            let stu = {
              stuId: element.id,
              stuNo: element.stuNo,
              stuName: element.stuName,
              gradeNo: element.gradeNo,
              facultyName: element.collName,
              facultyNo: element.collNo,
              specNo: element.sqecCode,
              specName: element.sqecName,
              classNo: element.classNo,
              className: element.className,
              checked: true,
            }
            this.form.leaveSchoolStudents.push(stu)
          })
        } else {
          this.$notify({
            type: "warning",
            message: "未检索到学生离校数据！",
            duration: 2000,
          });
        }
      })
    },
    cancel() {
      this.form = {
        id: null,
        teremYear: null,
        leaveSchoolType: null,
        schoolTerem: null,
        otherExplanation: null,
        classNo: null,
        staffNo: null,
        facultyName: null,
        facultyNo: null,
        specCode: null,
        specName: null,
        className: null,
        staffName: null,
        leaveSchoolStudents: [],
      }
      this.$router.go(-1)
    },
  }
}
</script>
<style lang="scss" scoped>
.van-field__label {
  width: 110px;
}
.textarea-style {
  height: 100% !important;
}
.van-cell {
  font-size: 15px;
  line-height: 32px;
  height: 50px;
}
.van-cell__left-icon,
.van-cell__right-icon {
  line-height: 32px;
}

::v-deep.submit-save {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 6px 0;
  line-height: var(--footer-height);
  text-align: center;
  &-subbtn {
    width: 90%;
    margin-left: 5%;
  }
}
</style>